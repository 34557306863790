export const FETCH_PREDICTIONS_FAILURE = 'FETCH_PREDICTIONS_FAILURE'
export const fetchPredictionsFailure = (errorType) => ({
  type: FETCH_PREDICTIONS_FAILURE,
  errorType,
})

export const FETCH_PLACE_FAILURE = 'FETCH_PLACE_FAILURE'
export const fetchPlaceFailure = (errorType) => ({
  type: FETCH_PLACE_FAILURE,
  errorType,
})
