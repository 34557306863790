import { STREET_FORMAT } from '../address'

import { findFirstAndApply } from './traversal'
import { addEventListener, removeEventListener } from './bindEvents'
import bindInput from './bindInput'
import bindStreetAutocomplete from './bindStreetAutocomplete'
import bindIfStateCondition from './bindIfStateCondition'

import { hide, show } from '../domTools'

export function bindCountrySelector(
  context,
  element,
  { supportedCountries, onCountryChanged }
) {
  return findFirstAndApply(element, 'select', (select) => {
    function handleChanged(event) {
      onCountryChanged(event.target.value)
    }

    if (select.querySelectorAll('option').length === 0) {
      Object.values(supportedCountries).forEach((country) => {
        const option = document.createElement('OPTION')
        option.value = country.countryCode
        option.text = country.name
        select.appendChild(option)
      })
    }

    context.subscribe(({ address }) => (select.value = address.countryCode))

    addEventListener(select, 'change', handleChanged)
    return () => {
      removeEventListener(select, 'change', handleChanged)
    }
  })
}

export function bindStreetAndHouse(
  context,
  element,
  {
    onStreetAndHouseNumberChanged,
    onFieldsTouched,
    onHintsKeyDown,
    onHintsTextChanged,
    onHintsBlur,
  }
) {
  return bindIfStateCondition(context, {
    condition: ({ visibleStreetFormat }) =>
      visibleStreetFormat === STREET_FORMAT.SINGLE_FIELD,

    bindAction: () =>
      bindStreetAutocomplete(context, element, {
        valueAccessor: (viewState) => viewState.address.streetAndHouseNumber,
        onChange: onStreetAndHouseNumberChanged,
        onBlur: () => onFieldsTouched('streetName', 'houseNumber'),
        onHintsKeyDown,
        onHintsTextChanged,
        onHintsBlur,
      }),
    onViewBound: () => show(element),
    onViewUnbound: () => hide(element),
  })
}

export function bindStreetName(
  context,
  element,
  {
    onStreetChanged,
    onFieldsTouched,
    onHintsKeyDown,
    onHintsTextChanged,
    onHintsBlur,
  }
) {
  return bindIfStateCondition(context, {
    condition: ({ visibleStreetFormat }) =>
      visibleStreetFormat === STREET_FORMAT.SEPARATE_HOUSE_NUMBER,

    bindAction: () =>
      bindStreetAutocomplete(context, element, {
        valueAccessor: (viewState) => viewState.address.streetName,
        onChange: onStreetChanged,
        onBlur: () => onFieldsTouched('streetName'),
        onHintsKeyDown,
        onHintsTextChanged,
        onHintsBlur,
      }),
  })
}

export function bindStreetAndHouseGroup(context, element) {
  return bindIfStateCondition(context, {
    condition: ({ visibleStreetFormat }) =>
      visibleStreetFormat === STREET_FORMAT.SEPARATE_HOUSE_NUMBER,

    onViewBound: () => show(element),
    onViewUnbound: () => hide(element),
  })
}

export function bindHouseNumber(
  context,
  element,
  { onHouseNumberChanged, onFieldsTouched }
) {
  return bindIfStateCondition(context, {
    condition: ({ visibleStreetFormat }) =>
      visibleStreetFormat === STREET_FORMAT.SEPARATE_HOUSE_NUMBER,

    bindAction: () =>
      bindInput(context, element, {
        valueAccessor: (viewState) => viewState.address.houseNumber,
        onChange: onHouseNumberChanged,
        onBlur: () => onFieldsTouched('houseNumber'),
      }),
  })
}

export function bindCity(context, element, { onCityChanged, onFieldsTouched }) {
  return bindInput(context, element, {
    valueAccessor: (viewState) => viewState.address.city,
    onChange: onCityChanged,
    onBlur: () => onFieldsTouched('city'),
  })
}

export function bindState(
  context,
  element,
  { onStateChanged, onFieldsTouched }
) {
  return bindIfStateCondition(context, {
    condition: ({ containsState }) => containsState,
    bindAction: () =>
      bindInput(context, element, {
        valueAccessor: (viewState) => viewState.address.state,
        onChange: onStateChanged,
        onBlur: () => onFieldsTouched('state'),
      }),
    onViewBound: () => show(element),
    onViewUnbound: () => hide(element),
  })
}

export function bindPostalCode(
  context,
  element,
  { onPostalCodeChanged, onFieldsTouched }
) {
  return bindInput(context, element, {
    valueAccessor: (viewState) => viewState.address.postalCode,
    onChange: onPostalCodeChanged,
    onBlur: () => onFieldsTouched('postalCode'),
  })
}
