import { findFirstAndApply } from './traversal'
import { addEventListener, removeEventListener } from './bindEvents'
import bindInputToState from './bindInputToState'

export default function bindInput(
  context,
  element,
  {
    // on each update, this function gets the new view state and returns input value
    valueAccessor,

    // UI event handlers
    onChange,
    onBlur,

    // Lifecycle event handlers
    onBoundToView,
    onViewStateUpdate,
  }
) {
  if (!element) {
    throw TypeError('bindInput requires an element to bind to')
  }
  const { subscribe } = context

  return findFirstAndApply(element, 'input', (inputElement) => {
    const binding = bindInputToState(
      context,
      inputElement,
      onChange,
      valueAccessor
    )

    let customCleanup
    if (typeof onBoundToView === 'function') {
      customCleanup = onBoundToView(inputElement)
    }

    if (typeof onViewStateUpdate === 'function') {
      subscribe((viewState) => onViewStateUpdate(viewState))
    }

    if (typeof onBlur === 'function') {
      addEventListener(inputElement, 'blur', onBlur)
    }
    return function cleanup() {
      if (typeof onBlur === 'function') {
        removeEventListener(inputElement, 'blur', onBlur)
      }
      if (typeof customCleanup === 'function') {
        customCleanup()
      }
      binding.unbind()
    }
  })
}
