import * as actions from './actions'
import * as selectors from './selectors'

import { getCountryFunctions } from '../../model/countries'
import { createAddress, STREET_FORMAT } from '../../model/address'

import {
  getFieldStatus,
  verifyAddressField,
  hideValidationErrorForField,
} from './reducerTools/validation'

import {
  ALL_FIELDS_VERIFIED,
  initEmptyAddressForCountry,
  changeAddressField,
  updateStreet,
  updateHouseNumber,
  breakDownStreetAndHouseNumber,
  getUserEnteredHouseNumber,
  setAddressAndValidate,
} from './reducerTools/updateAddress'

export function addressForm(
  state = {
    lastKnownVerifiedAddress: {},
    address: createAddress(),
    fieldStatuses: {
      ...ALL_FIELDS_VERIFIED,
    },
    currentCountryDetails: {
      containsState: getCountryFunctions().addressContainsState(),
      preferredStreetFormat: getCountryFunctions().preferredStreetFormat(),
    },
    isVerificationPending: false,
  },
  action
) {
  switch (action.type) {
    case actions.VALIDATE_FORM: {
      const address = breakDownStreetAndHouseNumber(state.address)
      return {
        ...state,
        address,
        fieldStatuses: {
          countryCode: getFieldStatus(
            state.lastKnownVerifiedAddress,
            address,
            'countryCode'
          ),
          state: getFieldStatus(
            state.lastKnownVerifiedAddress,
            address,
            'state'
          ),
          city: getFieldStatus(state.lastKnownVerifiedAddress, address, 'city'),
          houseNumber: getFieldStatus(
            state.lastKnownVerifiedAddress,
            address,
            'houseNumber'
          ),
          streetName: getFieldStatus(
            state.lastKnownVerifiedAddress,
            address,
            'streetName'
          ),
          postalCode: getFieldStatus(
            state.lastKnownVerifiedAddress,
            address,
            'postalCode'
          ),
        },
      }
    }

    case actions.SET_GOOGLE_PLACE: {
      const countryFunctions = getCountryFunctions(state.address.countryCode)
      const googleAddress = countryFunctions.convertGooglePlaceToAddress(
        action.place
      )

      let address = googleAddress

      // We want to keep the user entered house number in any case:
      // 1. Google gave us the same house number as the user entered -> we want to keep it (doesn't matter which one, both are the same)
      // 2. Google gave us different house number, we want to keep the one user entered.
      // 3. Google haven't given us any, we want to keep the one user entered.
      const userEnteredHouseNumber = getUserEnteredHouseNumber(
        countryFunctions,
        state.currentCountryDetails.visibleStreetFormat,
        state.address
      )
      if (userEnteredHouseNumber) {
        address = updateHouseNumber(address, userEnteredHouseNumber)
      }

      return setAddressAndValidate(state, googleAddress, address)
    }

    case actions.SET_GOOGLE_PLACE_IF_COMPLETE: {
      const countryFunctions = getCountryFunctions(state.address.countryCode)
      const googleAddress = countryFunctions.convertGooglePlaceToAddress(
        action.place
      )

      /* eslint-disable eqeqeq */
      if (
        !googleAddress ||
        !googleAddress.houseNumber ||
        googleAddress.houseNumber != state.address.houseNumber
      ) {
        // the address is not complete, we won't use it
        return {
          ...state,
          isVerificationPending: false,
        }
      }
      /* eslint-enable eqeqeq */

      return {
        ...setAddressAndValidate(state, googleAddress),
        isVerificationPending: false,
      }
    }

    case actions.CHANGE_COUNTRY_CODE: {
      return initEmptyAddressForCountry(state, action.countryCode)
    }

    case actions.CHANGE_STATE: {
      return changeAddressField(state, 'state', action)
    }

    case actions.CHANGE_CITY: {
      return changeAddressField(state, 'city', action)
    }

    case actions.CHANGE_STREET: {
      return hideValidationErrorForField(
        {
          ...state,
          address: updateStreet(state.address, action.value),
        },
        'streetName'
      )
    }

    case actions.CHANGE_HOUSE_NUMBER: {
      return hideValidationErrorForField(
        {
          ...state,
          address: updateHouseNumber(state.address, action.houseNumber),
        },
        'houseNumber'
      )
    }

    case actions.CHANGE_STREET_AND_HOUSE_NUMBER: {
      const countryFunctions = getCountryFunctions(state.address.countryCode)
      const street = countryFunctions.getStreet(action.value)
      const houseNumber = countryFunctions.getHouseNumber(action.value)

      let address = {
        ...state.address,
        streetAndHouseNumber: action.value,
        streetName: action.value,
        houseNumber: '',
      }
      if (street.length > 0 && houseNumber.length > 0) {
        address.streetName = street
        address.houseNumber = houseNumber
      }

      return hideValidationErrorForField(
        {
          ...state,
          address,
        },
        'streetName',
        'houseNumber'
      )
    }

    case actions.CHANGE_POSTAL_CODE: {
      return changeAddressField(state, 'postalCode', action)
    }

    case actions.VALIDATE_FIELDS: {
      let newState = state

      if (action.fieldNames.includes('streetName')) {
        newState.address = breakDownStreetAndHouseNumber(state.address)

        // always validate street and house number together
        if (!action.fieldNames.includes('houseNumber')) {
          action.fieldNames.push('houseNumber')
        }
      }

      for (let fieldName of action.fieldNames) {
        newState = verifyAddressField(newState, fieldName)
      }
      return newState
    }

    case actions.SET_FORM_METADATA: {
      // Clear the address whenever form structure changed.
      // There may be different layout and supported countries.
      return initEmptyAddressForCountry(
        {
          ...state,
          formMetadata: action.metadata,
        },
        action.metadata.defaultCountryCode
      )
    }

    case actions.SET_MANUAL_STREET_AND_HOUSE_NUMBER: {
      if (
        state.currentCountryDetails.visibleStreetFormat ===
        STREET_FORMAT.SEPARATE_HOUSE_NUMBER
      ) {
        return hideValidationErrorForField(
          {
            ...state,
            address: breakDownStreetAndHouseNumber(state.address),
          },
          'streetName',
          'houseNumber'
        )
      }

      return state
    }

    case actions.VERIFY_ADDRESS: {
      return {
        ...state,
        isVerificationPending: true,
      }
    }

    case actions.CANCEL_ADDRESS_VERIFICATION: {
      return {
        ...state,
        isVerificationPending: false,
      }
    }

    default:
      return state
  }
}

export { actions, selectors }
