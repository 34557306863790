import React from 'react'
import Script from 'react-load-script'

import mountAutocomplete from '../../../model/mountAutocompleteToDom'

export default function ApiLoader(props) {
  // When testing in CI, use the dist package. Otherwise, just use the live code.

  const [loaded, setLoaded] = React.useState(false)
  const shouldLoadProductionScripts = process.env['REACT_APP_TESTING'] === 'ci'

  // Effect hooks helps us to make sure we only call onApiLoaded after everything's
  // rendered and the API is loaded.
  React.useEffect(() => {
    if (window.cimpressAddressAutocomplete && loaded) {
      if (typeof props.onApiLoaded === 'function') {
        props.onApiLoaded()
      }
    }
  })

  if (!shouldLoadProductionScripts && loaded === false) {
    // there's nothing to do, we're using live code
    window.cimpressAddressAutocomplete = {
      mountAutocomplete,
    }
    setLoaded(true)
  }

  const distPackageLoaded = () => {
    setLoaded(true)
  }

  return (
    <>
      {shouldLoadProductionScripts ? (
        <Script url="/cimAddressAutocomplete.js" onLoad={distPackageLoaded} />
      ) : null}
      {loaded ? props.children : null}
    </>
  )
}
