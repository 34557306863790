import * as actions from './actions'
import * as selectors from './selectors'

export function error(state = { lastError: null }, action) {
  switch (action.type) {
    case actions.FETCH_PLACE_FAILURE:
    case actions.FETCH_PREDICTIONS_FAILURE:
      return {
        ...state,
        lastError: action,
      }

    default:
      return state
  }
}

export { actions, selectors }
