import { combineReducers } from 'redux'
import { addressForm } from './addressForm'
import { error } from './error'
import { autocomplete } from './autocomplete'
import { googleMapsApi } from './googleMapsApi'

export default combineReducers({
  addressForm,
  error,
  autocomplete,
  googleMapsApi,
})
