import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import Autocomplete from '../../components/Autocomplete'
import MapsApiLoader from '../../components/MapsApiLoader'

import { addEventListener, removeEventListener } from './bindEvents'
import bindInput from './bindInput'

let isBindingAlreadyInProgress = false

function callBack(cb, ...args) {
  if (typeof cb === 'function') cb(...args)
}

export default function bindStreetAutocomplete(
  context,
  element,
  {
    valueAccessor,
    onChange,
    onBlur,
    onHintsKeyDown,
    onHintsTextChanged,
    onHintsBlur,
  }
) {
  let shouldShowHints = true
  function onKeyDownHandler(event) {
    if (shouldShowHints) {
      callBack(onHintsKeyDown, event)
    }
  }

  function onChangedHandler(value) {
    if (shouldShowHints) {
      callBack(onHintsTextChanged, value)
    }
    callBack(onChange, value)
  }

  function onBlurHandler() {
    if (shouldShowHints) {
      callBack(onHintsBlur)
    }
    callBack(onBlur)
  }

  // Detect cycles (in case the react.render below triggers an state update)
  if (isBindingAlreadyInProgress) {
    return
  }
  isBindingAlreadyInProgress = true

  try {
    return bindInput(context, element, {
      valueAccessor,
      onChange: onChangedHandler,
      onBlur: onBlurHandler,
      onViewStateUpdate: ({ isAutocompleteAllowed }) =>
        (shouldShowHints = isAutocompleteAllowed),
      onBoundToView: (input) => {
        input.insertAdjacentHTML('afterend', '<div id="containingDiv"></div>')
        const containingDiv = input.nextElementSibling

        ReactDOM.render(
          <Provider store={context.store}>
            <MapsApiLoader>
              <Autocomplete />
            </MapsApiLoader>
          </Provider>,
          containingDiv
        )

        addEventListener(input, 'keydown', onKeyDownHandler)
        return function cleanup() {
          removeEventListener(input, 'keydown', onKeyDownHandler)
          ReactDOM.unmountComponentAtNode(containingDiv)
          containingDiv.parentNode.removeChild(containingDiv)
        }
      },
    })
  } finally {
    isBindingAlreadyInProgress = false
  }
}
