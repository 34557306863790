import * as actions from './actions'
import * as selectors from './selectors'

export function autocomplete(
  state = {
    predictions: [],
    menuIsOpen: false,
    selectedPredictionIndex: null,
  },
  action
) {
  switch (action.type) {
    case actions.FETCH_PREDICTIONS_SUCCESS:
      return {
        ...state,
        predictions: action.predictions,
      }
    case actions.SET_SELECTED_PREDICTION_INDEX:
      return {
        ...state,
        selectedPredictionIndex: action.predictionIndex,
      }
    case actions.HIDE_PREDICTIONS:
      return {
        ...state,
        menuIsOpen: false,
      }

    case actions.GO_TO_PREVIOUS_PREDICTION: {
      const selectedPredictionIndex =
        state.selectedPredictionIndex >= 0
          ? state.selectedPredictionIndex - 1
          : state.predictions.length - 1
      return {
        ...state,
        selectedPredictionIndex,
      }
    }

    case actions.GO_TO_NEXT_PREDICTION: {
      const selectedPredictionIndex =
        state.selectedPredictionIndex + 1 >= state.predictions.length
          ? -1
          : state.selectedPredictionIndex + 1
      return {
        ...state,
        selectedPredictionIndex,
      }
    }

    case actions.SHOW_PREDICTIONS:
      return {
        ...state,
        menuIsOpen: true,
        selectedPredictionIndex:
          state.predictions.length > 0 && action.shouldPreselectFirstOption
            ? 0
            : -1,
      }

    default:
      return state
  }
}

export { actions, selectors }
