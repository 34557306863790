/* global google */

import { singleConcurrentRequest } from './promiseTools'

const fakeDiv = document.createElement('div')
let sessionToken
let promisifiedGoogleFunctions

function getSessionToken() {
  if (!sessionToken) {
    sessionToken = new google.maps.places.AutocompleteSessionToken()
  }

  return sessionToken
}

function promisifyGoogle(func) {
  return function(...args) {
    return new Promise((resolve, reject) => {
      func(...args, (result, status) => {
        if (status === 'OK') {
          resolve(result)
        } else {
          reject(new Error(status))
        }
      })
    })
  }
}

function getGoogle() {
  if (promisifiedGoogleFunctions == null) {
    const autocompleteService = new google.maps.places.AutocompleteService()
    const placesService = new google.maps.places.PlacesService(fakeDiv)

    promisifiedGoogleFunctions = {
      autocompleteService,
      placesService,
      getPlacePredictions: promisifyGoogle(
        autocompleteService.getPlacePredictions.bind(autocompleteService)
      ),
      getPlaceDetails: promisifyGoogle(
        placesService.getDetails.bind(placesService)
      ),
    }
  }

  return promisifiedGoogleFunctions
}

// This is a function for testing purposes to provide a clean enviroment for each test case
export function _resetPromisifiedGoogleFunctions() {
  promisifiedGoogleFunctions = null
}

export const getPlacePredictions = singleConcurrentRequest(
  async (input, countryCode) => {
    if (input && input.length > 0) {
      // This is a hack that prevents predictions of other number than entered.
      // E.g. input 'Amstelveld 1' suggestion 'Amstelveld 11'
      if (input.match('.*\\d$')) {
        input += ' '
      }

      try {
        return await getGoogle().getPlacePredictions({
          input,
          sessionToken: getSessionToken(),
          types: ['address'],
          componentRestrictions: {
            country: [countryCode.toLowerCase()],
          },
        })
      } catch (e) {
        if (e.message === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          return []
        } else {
          throw e
        }
      }
    } else {
      return []
    }
  }
)

export const getPlace = async (placeId) => {
  try {
    return await getGoogle().getPlaceDetails({
      placeId,
      fields: ['address_components', 'types', 'name'],
      sessionToken: getSessionToken(),
    })
  } finally {
    sessionToken = null
  }
}

export const getPlaceId = (prediction) => prediction.place_id

export const findComponent = (requestedType, place) => {
  if (!Array.isArray(place.address_components)) {
    return {}
  }
  return (
    place.address_components.find((component) =>
      component.types.find((type) => type === requestedType)
    ) || {}
  )
}
export const getShortNameOf = (type, place) =>
  findComponent(type, place).short_name || ''
export const getLongNameOf = (type, place) =>
  findComponent(type, place).long_name || ''
