import {
  convertGooglePlaceToAddress,
  addressContainsState,
  preferredStreetFormat,
  getRequiredFieldsWithState as getRequiredFields,
} from './eu'
import { streetFunctions } from '../rightSideHouseNumber'

function validatePostalCode(value) {
  return value.trim().match(/^\d{4} [A-Z]{2}$/) !== null
}

export const NL = {
  countryCode: 'NL',
  name: 'Netherlands',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState,
    preferredStreetFormat,
    isAutocompleteAllowed: () => true,
    getRequiredFields,
  },
}
