import {
  selectors as googleMapsApiSelectors,
  actions as googleMapsApiActions,
} from '../../state/googleMapsApi'
import { connect } from 'react-redux'
import { MapsApiLoaderVisual } from './MapsApiLoaderVisual'

export default connect(
  (state) => ({
    isGoogleMapsApiLoaded: googleMapsApiSelectors.isLoaded(state),
  }),
  (dispatch) => ({
    onLoad: () => dispatch(googleMapsApiActions.googleMapsApiLoaded()),
    onError: () => console.error('Error on loading Google Maps API'),
  })
)(MapsApiLoaderVisual)
