import React, { Component } from 'react'
import { selectors, actions } from '../../state/autocomplete'
import * as addressForm from '../../state/addressForm'

import { connect } from 'react-redux'
import { AutocompleteVisual } from './AutocompleteVisual'

class AutocompleteComponent extends Component {
  onPredictionSelected = (index) => {
    if (this.props.isAutocompleteAllowed) {
      this.props.selectPrediction(index)
    }
  }

  render() {
    return (
      <AutocompleteVisual
        menuIsOpen={this.props.menuIsOpen}
        onPredictionSelected={this.onPredictionSelected}
        predictions={this.props.predictions}
        selectedPredictionIndex={this.props.selectedPredictionIndex}
      />
    )
  }
}

export default connect(
  (state) => ({
    menuIsOpen: selectors.isMenuOpen(state),
    predictions: selectors.getPredictions(state),
    selectedPredictionIndex: selectors.getSelectedPredictionIndex(state),
    isAutocompleteAllowed: addressForm.selectors.getIsAutocompleteAllowed(
      state
    ),
  }),
  (dispatch) => ({
    selectPrediction: (predictionId) =>
      dispatch(actions.selectPrediction(predictionId)),
  })
)(AutocompleteComponent)
