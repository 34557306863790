import React from 'react'

import * as fields from '../../model/mountAutocompleteToDom/fieldNames'
import KNOWN_ERROR_FIELDS from '../../model/mountAutocompleteToDom/knownErrorFields'

import './ToolTip.css'

const FIELD_INFO = {
  country: {
    type: 'select',
    description:
      'Marks the country selector. The form will either adopt countries from the existing <option> children or fill in all supported countries if there are no children. Values need to be ISO 3166 country codes.',
  },
  streetName: {
    type: 'input',
    description:
      'Marks the separate street name field without house number used for European style addresses. If you use it, you must have a houseNumber field in the form too.',
  },
  houseNumber: {
    type: 'input',
    description:
      'Marks the separate house number field used for European style addresses. If you use it, you must have a streetName field in the form too.',
  },
  streetNameAndHouseNumber: {
    type: 'input',
    description:
      'Marks the joint street name and house number field used for US and UK-like addresses. You can use either streetName/houseNumber, streetNameAndHouseNumber or both. If you use both, the one not suitable for currently selected country will be hidden, but you also need to specify streetNameAndHouseNumberGroup.',
  },
  streetNameAndHouseNumberGroup: {
    type: 'div',
    description:
      'Use this div to group streetName and houseNumber fields together. The whole group will be hidden if a country that prefers unified streetNameAndHouseNumber field is selected and a streetNameAndHouseNumber field exists in the form. If you do not need this functionality you do not have to specify this group.',
  },
  city: {
    type: 'input',
    description: 'Marks the city input field.',
  },
  state: {
    type: 'input',
    description:
      'Marks the state input field. Only some countries need that. (US, Italy, ...) For other countries the element with this data-address-role will be automatically hidden.',
  },
  postalCode: {
    type: 'input',
    description: 'Marks the postalCode input field.',
  },
}

const asterisk = (
  <em>
    <sup>{'*)'}</sup>
  </em>
)

export default function DocFields() {
  const fieldDocRows = []

  for (let key in fields) {
    const fieldName = fields[key]
    const fieldInfo = FIELD_INFO[fieldName] || {}

    fieldDocRows.push(
      <tr key={fieldName}>
        <td>
          {fieldName}
          {!KNOWN_ERROR_FIELDS.includes(fieldName) ? asterisk : null}
        </td>
        <td>{fieldInfo.type}</td>
        <td>{fieldInfo.description}</td>
      </tr>
    )
  }

  return (
    <>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">
              Field name{' '}
              <span
                data-tip="Use for data-address-role and data-error-for-fields attributes."
                className="tool"
              >
                <span className="badge badge-secondary">i</span>
              </span>
            </th>
            <th scope="col">
              Supported element{' '}
              <span
                data-tip="The tagged element has to either be of this type or contain a children of this type."
                className="tool"
              >
                <span className="badge badge-secondary">i</span>
              </span>
            </th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {fieldDocRows}
          <tr className="table-secondary">
            <td colSpan="3">
              <em className="small">
                Fields marked with *) cannot be used with error visualisation.
              </em>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
