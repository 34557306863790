import React from 'react'
import { AddressAutocomplete, ErrorVisual } from '../../AddressAutocomplete'

const errorMessageMap = {
  streetName: 'Straat is een verplicht veld',
  houseNumber: 'Huisnummer is een verplicht veld',
  city: 'Stad is een verplicht veld',
  state: 'Staat is een verplicht veld',
  postalCode: {
    DEFAULT: 'Postcode is een verplicht veld',
    REASON_INVALID_FORMAT: 'Postcode moet in een geldig formaat zijn',
  },
}

function CustomErrorVisual(props) {
  return (
    <ErrorVisual
      {...props}
      FormatError={(props) => <label className="error">{props.children}</label>}
      FormatPlaceholder={(props) => (
        <label>
          <small>{props.children}</small>
        </label>
      )}
    />
  )
}

let validateForm
const onFormBound = (inst) => (validateForm = inst.validateForm)

async function onSubmit() {
  console.log(await validateForm())
}

export default function EuropeanForm() {
  return (
    <div className="container demoAddressForm">
      <AddressAutocomplete
        onFormBound={onFormBound}
        errorMessageMap={errorMessageMap}
      >
        <div className="row" key="row1">
          <div className="col-md-3" key="col1">
            <label className="required">Land</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <select
              data-address-role="country"
              className="form-control"
              placeholder=""
            >
              <option value="BE" key="BE">
                Belgium
              </option>
              <option value="CZ" key="CZ">
                Czech Republic
              </option>
              <option value="DE" key="DE">
                Germany
              </option>
              <option value="IT" key="IT">
                Italy
              </option>
              <option value="NL" key="NL">
                Netherlands
              </option>
              <option value="GB" key="GB">
                United Kingdom
              </option>
              <option value="US" key="US">
                United States
              </option>
              <option value="IE" key="IE">
                Ireland
              </option>
            </select>
          </div>
        </div>

        <div className="row" key="row2">
          <div className="col-md-3" key="col1">
            <label className="required">Adres</label>
          </div>

          <div className="col-md-5" key="col2">
            <input
              data-address-role="streetName"
              className="form-control"
              placeholder="Straatnaam"
              key="input"
            />
            <CustomErrorVisual
              key="errorVisual"
              fieldKeys={['streetName']}
              placeHolder="Straatnaam"
            />
          </div>

          <div className="col-md-2" key="col3">
            <input
              data-address-role="houseNumber"
              className="form-control"
              placeholder="Nummer"
              key="input"
            />
            <CustomErrorVisual
              key="errorVisual"
              fieldKeys={['houseNumber']}
              placeHolder="Nr."
            />
          </div>

          <div className="col-md-2 mb-3" key="col4">
            <input
              className="form-control"
              placeholder="Toevoeging"
              key="input"
            />
            <label key="label">
              <small>Toevoeging</small>
            </label>
          </div>
        </div>

        <div className="row" key="row3">
          <div className="col-md-3" key="col1">
            <label>Extra adresinformatie</label>
          </div>
          <div className="col-md-7 mb-3" key="col2">
            <input className="form-control" placeholder="" />
          </div>
        </div>

        <div className="row" key="row4">
          <div className="col-md-3" key="col1">
            <label className="required">Plaats</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              key="input"
              data-address-role="city"
              className="form-control"
              placeholder=""
            />
            <CustomErrorVisual key="errorVisual" fieldKeys="city" />
          </div>
        </div>

        <div className="row" key="state" data-address-role="state">
          <div className="col-md-3" key="col1">
            <label className="required">State</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input className="form-control" placeholder="" />
          </div>
        </div>

        <div className="row" key="row5">
          <div className="col-md-3" key="col1">
            <label className="required">Postcode</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              key="input"
              data-address-role="postalCode"
              className="form-control"
              placeholder=""
            />
            <CustomErrorVisual key="errorVisual" fieldKeys="postalCode" />
          </div>
        </div>
      </AddressAutocomplete>

      <hr className="mb-4" />

      <div className="row">
        <button className="btn btn-primary btn-lg btn-block" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}
