import { STREET_FORMAT } from '../address'

import { streetFunctions } from '../leftSideHouseNumber'
import { getLongNameOf, getShortNameOf } from '../googlePlacesApi'

function validatePostalCode(value) {
  /**
   * https://stackoverflow.com/questions/33391412/validation-for-irish-eircode
   */
  return (
    value
      .trim()
      .match(/(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/) !==
    null
  )
}

const convertGooglePlaceToAddress = (formatStreet) => (place) => {
  const getShortName = (type) => getShortNameOf(type, place)
  const getLongName = (type) => getLongNameOf(type, place)

  const countryCode = getShortName('country').toUpperCase()
  const state = getLongName('administrative_area_level_1')
  const city = getLongName('postal_town') || getLongName('locality')
  const houseNumber = getLongName('street_number')
  const streetName = getShortName('route')
  const postalCode = getShortName('postal_code')
  const streetAndHouseNumber = formatStreet({ streetName, houseNumber })

  return {
    countryCode,
    state,
    city,
    streetAndHouseNumber,
    streetName,
    houseNumber,
    postalCode,
  }
}

export const IE = {
  countryCode: 'IE',
  name: 'Ireland',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState: () => false,
    preferredStreetFormat: () => STREET_FORMAT.SINGLE_FIELD,
    getRequiredFields: () => [
      'countryCode',
      'streetName',
      'city',
      'postalCode',
    ],
    isAutocompleteAllowed: () => true,
  },
}
