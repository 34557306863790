import { STREET_FORMAT } from '../address'

const convertGooglePlaceToAddress = () => {}
const validatePostalCode = () => true
const getStreet = () => ''
const getHouseNumber = () => ''
const formatStreet = () => ''
const addressContainsState = () => false
const preferredStreetFormat = () => STREET_FORMAT.SEPARATE_HOUSE_NUMBER
const isAutocompleteAllowed = () => false
const getRequiredFields = () => [
  'countryCode',
  'streetName',
  'houseNumber',
  'city',
  'postalCode',
]

export const DUMMY = {
  countryCode: '',
  name: '',
  functions: {
    convertGooglePlaceToAddress,
    validatePostalCode,
    getStreet,
    getHouseNumber,
    formatStreet,
    addressContainsState,
    preferredStreetFormat,
    isAutocompleteAllowed,
    getRequiredFields,
  },
}
