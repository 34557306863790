import { getCountryFunctions } from '../../../model/countries'
import {
  createAddress,
  ADDRESS_FIELD_VERIFIED,
  STREET_FORMAT,
} from '../../../model/address'

import { getFieldStatus, hideValidationErrorForField } from './validation'

const ALL_FIELDS_VERIFIED = {
  countryCode: { status: ADDRESS_FIELD_VERIFIED },
  state: { status: ADDRESS_FIELD_VERIFIED },
  city: { status: ADDRESS_FIELD_VERIFIED },
  streetName: { status: ADDRESS_FIELD_VERIFIED },
  houseNumber: { status: ADDRESS_FIELD_VERIFIED },
  postalCode: { status: ADDRESS_FIELD_VERIFIED },
}

function getVisibleStreetFormat(preferredStreetFormat, formMetadata) {
  if (
    !formMetadata.streetAndHouse.unifiedFieldFound ||
    (formMetadata.streetAndHouse.streetFound &&
      preferredStreetFormat === STREET_FORMAT.SEPARATE_HOUSE_NUMBER)
  ) {
    return STREET_FORMAT.SEPARATE_HOUSE_NUMBER
  }

  if (
    !formMetadata.streetAndHouse.groupFound ||
    (formMetadata.streetAndHouse.unifiedFieldFound &&
      preferredStreetFormat === STREET_FORMAT.SINGLE_FIELD)
  ) {
    return STREET_FORMAT.SINGLE_FIELD
  }

  console.error(
    'Address autocomplete: Could not show the correct street / houseNumber field. This should never happen!'
  )
  return STREET_FORMAT.SINGLE_FIELD
}

function initEmptyAddressForCountry(state, countryCode) {
  const address = createAddress(countryCode)
  const countryFunctions = getCountryFunctions(countryCode)

  return {
    ...state,
    address,
    lastKnownVerifiedAddress: {},
    fieldStatuses: {
      ...ALL_FIELDS_VERIFIED,
    },
    currentCountryDetails: {
      containsState: countryFunctions.addressContainsState(),
      preferredStreetFormat: countryFunctions.preferredStreetFormat(),
      isAutocompleteAllowed: countryFunctions.isAutocompleteAllowed(),
      visibleStreetFormat: getVisibleStreetFormat(
        countryFunctions.preferredStreetFormat(),
        state.formMetadata
      ),
    },
  }
}

function changeAddressField(state, fieldName, action) {
  return hideValidationErrorForField(
    {
      ...state,
      address: {
        ...state.address,
        [fieldName]: action[fieldName],
      },
    },
    fieldName
  )
}

function updateStreet(address, newStreet) {
  const countryFunctions = getCountryFunctions(address.countryCode)

  return {
    ...address,
    streetName: newStreet,
    streetAndHouseNumber: countryFunctions.formatStreet({
      streetName: newStreet,
      houseNumber: address.houseNumber,
    }),
  }
}

/**
 * For cases when user forgets space between house number and street:
 * For input 12Some street, original house number would be 12Some, after
 * applying this function, it will be 12
 */
function removeStreetFromHouseNumber(houseNumber, street) {
  const streetParts = street.split(' ')
  if (streetParts.length && typeof houseNumber === 'string') {
    return houseNumber.replace(streetParts[0], '')
  } else {
    return houseNumber
  }
}

function updateHouseNumber(address, newHouseNumber) {
  const countryFunctions = getCountryFunctions(address.countryCode)

  return {
    ...address,
    houseNumber: removeStreetFromHouseNumber(
      newHouseNumber,
      address.streetName
    ),
    streetAndHouseNumber: countryFunctions.formatStreet({
      streetName: address.streetName,
      houseNumber: removeStreetFromHouseNumber(
        newHouseNumber,
        address.streetName
      ),
    }),
  }
}

function breakDownStreetAndHouseNumber(address) {
  const countryFunctions = getCountryFunctions(address.countryCode)
  const streetName = countryFunctions.getStreet(address.streetName)
  const houseNumber = countryFunctions.getHouseNumber(address.streetName)

  if (streetName !== address.streetName && houseNumber.length > 0) {
    return {
      ...address,
      streetName,
      houseNumber: houseNumber,
      streetAndHouseNumber: countryFunctions.formatStreet({
        streetName,
        houseNumber: houseNumber,
      }),
    }
  } else {
    return address
  }
}

function getUserEnteredHouseNumber(
  countryFunctions,
  visibleStreetFormat,
  address
) {
  if (visibleStreetFormat === STREET_FORMAT.SINGLE_FIELD) {
    return countryFunctions.getHouseNumber(address.streetAndHouseNumber)
  } else {
    return countryFunctions.getHouseNumber(address.streetName)
  }
}

function setAddressAndValidate(
  state,
  verifiedAddress,
  addressToSet = verifiedAddress
) {
  return {
    ...state,
    address: {
      ...state.address,
      ...addressToSet,
    },
    lastKnownVerifiedAddress: verifiedAddress,
    fieldStatuses: {
      countryCode: getFieldStatus(verifiedAddress, addressToSet, 'countryCode'),
      state: getFieldStatus(verifiedAddress, addressToSet, 'state'),
      city: getFieldStatus(verifiedAddress, addressToSet, 'city'),
      houseNumber: getFieldStatus(verifiedAddress, addressToSet, 'houseNumber'),
      streetName: getFieldStatus(verifiedAddress, addressToSet, 'streetName'),
      postalCode: getFieldStatus(verifiedAddress, addressToSet, 'postalCode'),
    },
  }
}

export {
  ALL_FIELDS_VERIFIED,
  initEmptyAddressForCountry,
  changeAddressField,
  updateStreet,
  updateHouseNumber,
  breakDownStreetAndHouseNumber,
  getUserEnteredHouseNumber,
  setAddressAndValidate,
}
