import {
  convertGooglePlaceToAddress,
  addressContainsState,
  preferredStreetFormat,
  getRequiredFieldsNoState as getRequiredFields,
} from './eu'
import { streetFunctions } from '../rightSideHouseNumber'

function validatePostalCode(value) {
  return value.trim().match(/^\d{4}$/) !== null
}

export const BE = {
  countryCode: 'BE',
  name: 'Belgium',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState,
    preferredStreetFormat,
    getRequiredFields,
    isAutocompleteAllowed: () => true,
  },
}
