function hide(el) {
  if (el && el.style) {
    el.style.display = 'none'
  }
}

function show(el) {
  if (el && el.style) {
    el.style.display = ''
  }
}

function matches(el, selector) {
  if (!el) {
    return false
  }

  return (
    el.matches ||
    el.matchesSelector ||
    el.msMatchesSelector ||
    el.mozMatchesSelector ||
    el.webkitMatchesSelector ||
    el.oMatchesSelector
  ).call(el, selector)
}

export { hide, show, matches }
