import { DUMMY } from './dummy'
export { STREET_FORMAT } from '../address'

export const SUPPORTED_COUNTRIES = {}

function registerCountry({ countryCode, name, functions }) {
  SUPPORTED_COUNTRIES[countryCode] = {
    countryCode,
    name,
    functions,
  }
}

export function getCountryFunctions(countryCode) {
  if (!countryCode || !SUPPORTED_COUNTRIES[countryCode]) {
    return DUMMY.functions
  }
  return SUPPORTED_COUNTRIES[countryCode].functions
}

// eslint-disable-next-line import/first
import { NL } from './nl'
registerCountry(NL)

// eslint-disable-next-line import/first
import { US } from './us'
registerCountry(US)

// eslint-disable-next-line import/first
import { BE } from './be'
registerCountry(BE)

// eslint-disable-next-line import/first
import { GB } from './gb'
registerCountry(GB)

// eslint-disable-next-line import/first
import { IT } from './it'
registerCountry(IT)

// eslint-disable-next-line import/first
import { DE } from './de'
registerCountry(DE)

// eslint-disable-next-line import/first
import { IE } from './ie'
registerCountry(IE)
