function addEventListener(el, eventName, handler) {
  if (el.addEventListener) {
    el.addEventListener(eventName, handler)
  } else {
    el.attachEvent('on' + eventName, function() {
      handler.call(el)
    })
  }
}

function removeEventListener(el, eventName, handler) {
  if (el.removeEventListener) el.removeEventListener(eventName, handler)
  else el.detachEvent('on' + eventName, handler)
}

export { addEventListener, removeEventListener }
