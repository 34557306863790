import {
  getPlacePredictions,
  getPlace,
  getPlaceId,
} from '../../model/googlePlacesApi'
import * as selectors from './selectors'
import * as addressFormActions from '../addressForm/actions'
import * as addressFormSelectors from '../addressForm/selectors'
import { fetchPlaceFailure, fetchPredictionsFailure } from '../error/actions'

export const FETCH_PREDICTIONS_SUCCESS = 'FETCH_PREDICTIONS_SUCCESS'
export const SHOW_PREDICTIONS = 'SHOW_PREDICTIONS'
export const HIDE_PREDICTIONS = 'HIDE_PREDICTIONS'
export const SET_SELECTED_PREDICTION_INDEX = 'SET_SELECTED_PREDICTION_INDEX'
export const GO_TO_NEXT_PREDICTION = 'GO_TO_NEXT_PREDICTION'
export const GO_TO_PREVIOUS_PREDICTION = 'GO_TO_PREVIOUS_PREDICTION'
export const REMEMBER_SEARCH_TEXT = 'REMEMBER_SEARCH_TEXT'

export const ARROW_UP_KEY_CODE = 38
export const ARROW_DOWN_KEY_CODE = 40
export const ESCAPE_KEY_CODE = 27
export const ENTER_KEY_CODE = 13

export const fetchPredictionsSuccess = (predictions) => ({
  type: FETCH_PREDICTIONS_SUCCESS,
  predictions,
})

export const showPredictions = (shouldPreselectFirstOption = true) => ({
  type: SHOW_PREDICTIONS,
  shouldPreselectFirstOption,
})

export const hidePredictions = () => ({
  type: HIDE_PREDICTIONS,
})

export const goToNextPrediction = () => ({
  type: GO_TO_NEXT_PREDICTION,
})

export const goToPreviousPrediction = () => ({
  type: GO_TO_PREVIOUS_PREDICTION,
})

const nextPredictionRequested = (event, state) =>
  event.keyCode === ARROW_DOWN_KEY_CODE && selectors.isMenuOpen(state)
const openMenuRequested = (event, state) =>
  event.keyCode === ARROW_DOWN_KEY_CODE && !selectors.isMenuOpen(state)
const previousPredictionRequested = (event, state) =>
  event.keyCode === ARROW_UP_KEY_CODE && selectors.isMenuOpen(state)
const closeMenuRequested = (event, state) =>
  event.keyCode === ESCAPE_KEY_CODE && selectors.isMenuOpen(state)
const userSelectedPrediction = (event, state) =>
  event.keyCode === ENTER_KEY_CODE &&
  selectors.getSelectedPredictionIndex(state) >= 0 &&
  selectors.isMenuOpen(state)
const userNotSelectedPrediction = (event, state) =>
  event.keyCode === ENTER_KEY_CODE &&
  selectors.getSelectedPredictionIndex(state) < 0

export function processKey(event) {
  return (dispatch, getState) => {
    const state = getState()

    if (nextPredictionRequested(event, state)) {
      event.preventDefault()
      return dispatch(goToNextPrediction())
    } else if (openMenuRequested(event, state)) {
      event.preventDefault()
      return dispatch(showPredictions())
    } else if (previousPredictionRequested(event, state)) {
      event.preventDefault()
      return dispatch(goToPreviousPrediction())
    } else if (closeMenuRequested(event, state)) {
      event.preventDefault()
      return dispatch(hidePredictions())
    } else if (userSelectedPrediction(event, state)) {
      event.preventDefault()
      dispatch(hidePredictions())
      return dispatch(
        selectPrediction(selectors.getSelectedPredictionIndex(state))
      )
    } else if (userNotSelectedPrediction(event, state)) {
      event.preventDefault()
      if (selectors.isMenuOpen(state)) {
        dispatch(hidePredictions())
      }
      return dispatch(addressFormActions.setManualStreetAndHouseNumber())
    }
  }
}

export function fetchPredictions(input) {
  return async (dispatch, getState) => {
    const state = getState()
    const countryCode = addressFormSelectors.getAddress(state).countryCode

    try {
      const predictions = await getPlacePredictions(input, countryCode)
      if (predictions.length > 0) {
        dispatch(fetchPredictionsSuccess(predictions))
        dispatch(
          showPredictions(
            addressFormSelectors.getHasStreetChangedSinceValidation(getState())
          )
        )
      } else {
        dispatch(fetchPredictionsSuccess([]))
        dispatch(hidePredictions())
      }
    } catch (error) {
      dispatch(fetchPredictionsFailure(error.message))
    }
  }
}

export function selectPrediction(predictionId) {
  return async (dispatch, getState) => {
    const state = getState()
    const predictions = selectors.getPredictions(state)
    if (!predictions || !predictions.length) {
      return
    }
    const placeId = getPlaceId(predictions[predictionId])

    try {
      dispatch(addressFormActions.setGooglePlace(await getPlace(placeId)))
      dispatch(hidePredictions())
    } catch (error) {
      dispatch(fetchPlaceFailure(error.message))
    }
  }
}
