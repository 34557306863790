import React from 'react'
import Script from 'react-load-script'

export const MapsApiLoaderVisual = (props) => {
  // In testing environment, google maps API is mocked and blocked.
  // We show the content regardless to whether api gets loaded.
  const children =
    process.env['REACT_APP_TESTING'] != null || props.isGoogleMapsApiLoaded
      ? props.children
      : null

  return (
    <>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBQ3r1fOmIIdNzYi3QBF5lV6lLihRi4_DM&v=3.exp&libraries=places"
        onLoad={props.onLoad}
        onError={props.onError}
      />

      {children}
    </>
  )
}
