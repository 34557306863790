/*
 * `matchedSubstringIntervals` is an array of offsets & lengths of
 * matched substrings. This function splits `inputString` into a
 * sequence of all unmatched and matched substrings.
 */
export function getAllSubstrings(inputString, matchedSubstringIntervals) {
  const allSubstringIntervals = []

  let currentIndex = 0
  matchedSubstringIntervals.forEach((matchedSubstring) => {
    // Unmatched substring before the matched one
    allSubstringIntervals.push({
      offset: currentIndex,
      length: matchedSubstring.offset - currentIndex,
      matched: false,
    })

    allSubstringIntervals.push({
      ...matchedSubstring,
      matched: true,
    })

    currentIndex = matchedSubstring.offset + matchedSubstring.length
  })

  // A substring next to the last matched one is unmatched
  allSubstringIntervals.push({
    offset: currentIndex,
    length: inputString.length - currentIndex + 1,
    matched: false,
  })

  return allSubstringIntervals.map((substring) => ({
    value:
      substring.length > 0
        ? inputString.slice(
            substring.offset,
            substring.offset + substring.length
          )
        : '',
    matched: substring.matched,
  }))
}
