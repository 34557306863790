import React from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { DemoFormReact, DemoFormPlain } from '../DemoForms'

import './app.css'

function Menu() {
  return (
    <>
      <h2 className="offset-sm-2 my-4">Choose your integration method</h2>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 offset-sm-2">
            <div className="mx-auto" style={{ width: '256px' }}>
              <Link to="/react">
                <button className="btn">
                  <img
                    alt="React implementation demo"
                    className="img-thumbnail"
                    src="./img/reactLogo.png"
                  />
                </button>
              </Link>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="mx-auto" style={{ width: '256px' }}>
              <Link to="/plainJs">
                <button className="btn">
                  <img
                    alt="Plain JS implementation demo"
                    width="256px"
                    className="img-thumbnail"
                    src="./img/javascriptLogo.png"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Menu} />
        <Route exact path="/react/" component={DemoFormReact} />
        <Route exact path="/plainJs/" component={DemoFormPlain} />
      </div>
    </Router>
  )
}

export default App
