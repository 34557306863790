import { STREET_FORMAT } from '../address'
import { getLongNameOf, getShortNameOf } from './../googlePlacesApi'
import { streetFunctions } from '../leftSideHouseNumber'

function validatePostalCode(value) {
  return value.trim().match(/^\d{5}$/) !== null
}

function convertGooglePlaceToAddress(place) {
  const getShortName = (type) => getShortNameOf(type, place)
  const getLongName = (type) => getLongNameOf(type, place)

  const countryCode = getShortName('country').toUpperCase()
  const state = getLongName('administrative_area_level_1')
  const city = getLongName('locality') || getLongName('sublocality_level_1')
  const houseNumber = getLongName('street_number')
  const streetName = getShortName('route')
  const postalCode = getShortName('postal_code')
  const streetAndHouseNumber = place.name

  return {
    countryCode,
    state,
    city,
    streetName,
    houseNumber,
    streetAndHouseNumber,
    postalCode,
  }
}

export const US = {
  countryCode: 'US',
  name: 'United States of America',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress,
    ...streetFunctions,
    addressContainsState: () => true,
    preferredStreetFormat: () => STREET_FORMAT.SINGLE_FIELD,
    isAutocompleteAllowed: () => true,
    getRequiredFields: () => [
      'countryCode',
      'streetName',
      'houseNumber',
      'city',
      'postalCode',
      'state',
    ],
  },
}
