import React from 'react'

export default function HowToErrorVisuals() {
  return (
    <>
      You can use data-error-for-fields tag on any html element that can show
      text content like div, span or label. Use stylesheets to style it any way
      you like. The data-error-for-fields tag can contain any field names that
      supports validation delimited by spaces. The element will automatically
      display validation errors for all specified fields, but only one at the
      same time. The priority of error messages is determined by order in which
      the field names are specified.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-html card-body">
            {`<span data-error-for-fields="streetName houseNumber"></span>`}
          </code>
        </pre>
      </div>
      Sometimes it is useful to show place holder content when there is no
      error. You can do it as shown in the folowing example.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-html card-body">
            {`<div data-error-for-fields="streetName houseNumber">
    <label className="error" data-error-role="content" />
    <div data-error-role="placeHolder">
      <label>
        <small>Street name</small>
      </label>
    </div>
  </div>`}
          </code>
        </pre>
      </div>
      Default error messages are hard coded and written in English. You can
      override and localize like so.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-javascript card-body">
            {`window.myForm = window.cimpressAddressAutocomplete.mountAutocomplete(
    document.querySelector('.some-form-selector'),
    {
      errorMessageMap: {
        streetName: 'Straat is een verplicht veld',
        houseNumber: 'Huisnummer is een verplicht veld',
        city: 'Stad is een verplicht veld',
        state: 'Staat is een verplicht veld',
        postalCode: {
          DEFAULT: 'Postcode is een verplicht veld',
          REASON_INVALID_FORMAT: 'Postcode moet in een geldig formaat zijn',
        },
      },
    }
  )`}
          </code>
        </pre>
      </div>
    </>
  )
}
