import React from 'react'

import { getAllSubstrings } from '../../model/getAllSubstrings'
import style from './autocomplete.scss'
const addNamespace = (classNames) => `cimTechAutocomplete-${classNames}`

// this doesn't really do anything apart from making the style-loader add the stylesheets to the page
// eslint-disable-next-line no-unused-vars
const usedStyle = style

const highlightMatchedSubstrings = (input, matchedSubstrings) => {
  if (!matchedSubstrings) {
    return input
  }

  return getAllSubstrings(input, matchedSubstrings).map(
    (substringObj, index) => {
      if (substringObj.matched) {
        return (
          <span key={index} style={{ fontWeight: 'bold' }}>
            {substringObj.value}
          </span>
        )
      } else {
        return <span key={index}>{substringObj.value}</span>
      }
    }
  )
}

const renderPredictions = (props) => {
  return props.predictions.map((prediction, index) => (
    <div
      key={index}
      className={`${addNamespace('autocompleteItem')} ${
        index === props.selectedPredictionIndex
          ? addNamespace('autocompleteItemSelected')
          : ''
      }`}
      onMouseDown={() => props.onPredictionSelected(index)}
    >
      <span className={addNamespace('iconMarker')} />
      <span className={addNamespace('autocompleteItemPrimaryText')}>
        {highlightMatchedSubstrings(
          prediction.structured_formatting.main_text,
          prediction.structured_formatting.main_text_matched_substrings
        )}
      </span>

      <span className={addNamespace('autocompleteItemSecondaryText')}>
        {highlightMatchedSubstrings(
          prediction.structured_formatting.secondary_text,
          prediction.structured_formatting.secondary_text_matched_substrings
        )}
      </span>
    </div>
  ))
}

export const AutocompleteVisual = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      {props.menuIsOpen && props.predictions && props.predictions.length > 0 ? (
        <div className={addNamespace('autocompleteItems')}>
          {renderPredictions(props)}
          <div className={addNamespace('autocompletePoweredWithGoogle')} />
        </div>
      ) : null}
    </div>
  )
}
