import React from 'react'

import EuropeanForm from './EuropeanForm'
import SmartSwitchingForm from './SmartSwitchingForm'
import USForm from './USForm'
import '../DemoForm.css'

const demoComponents = {
  SMART: { label: 'Smart switching form', component: SmartSwitchingForm },
  EU: { label: 'European style form', component: EuropeanForm },
  US: { label: 'US style form', component: USForm },
}

const DEFAULT_COMPONENT = 'SMART'

export default class DemoFormReact extends React.Component {
  state = {
    form: DEFAULT_COMPONENT,
  }

  setForm(form) {
    this.setState({
      form,
    })
  }

  render() {
    const FormComponent = demoComponents[this.state.form].component

    const options = []
    for (let [key, { label }] of Object.entries(demoComponents)) {
      options.push(
        <option value={key} key={key}>
          {label}
        </option>
      )
    }

    return (
      <div className="container demoAddressForm">
        <h2>Address form</h2>
        <div className="row commonForm">
          <div className="col-md-2">
            <label>Type:</label>
          </div>
          <select
            id="form-selector"
            className="col-md-10 form-control"
            value={this.state.form}
            onChange={(ev) => this.setForm(ev.target.value)}
          >
            {options}
          </select>
        </div>
        <hr />
        <FormComponent />
      </div>
    )
  }
}
