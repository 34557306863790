import React from 'react'
import APP_VERSION from '../../../../model/appVersion'
const reactComponentLink = `https://package.autocomplete.logistics.cimpress.io/react-component/address-autocomplete-${APP_VERSION}.tgz`

export default function QuickStart() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 card">
          <code className="card-body">
            npm install <a href={reactComponentLink}>{reactComponentLink}</a>
          </code>
        </div>
      </div>

      <div className="row mt-4 mb-3">
        <div className="col-md-12 card">
          <pre>
            <code className="language-jsx card-body">
              {`
import { AddressAutocomplete, ErrorVisual } from 'address-autocomplete'

class MyForm extends Component {
  render() {
    return (
      <AddressAutocomplete onFormBound={form => (this.form = form)}>

        {/* ... Your form goes here ... */}

        <select data-address-role="country">
          {/* Use any countries you need. We'll turn on autocomplete for those
              that are supported. */}
          <option vaule="NE">Netherlands</option>
        </select>

        {/* Use data-address-role to bind address fields.  */}
        <input data-address-role="streetNameAndHouseNumber" />

        {/* ErrorVisual component visualizes validation and verification errors
            for given address fields. */}
        <ErrorVisual fieldKeys={["streetName", "houseNumber"]} />

        <button type="button" onClick={this.submit}>Submit</button>

      </AddressAutocomplete>
    )
  }

  submit = async () => {
    try {
      const validationState = await this.form.validateForm()
      // You can decide what to do based on validation status
      // of the address user entered.
    }
    catch (e) {
      if (e === this.form.validateForm.CANCELED) {
        // The promise will get rejected in case the user
        // hits 'submit' again while validating.
    }
  };
}`}
            </code>
          </pre>
        </div>
      </div>
    </>
  )
}
