// 2200S Market St (alphanumeric street number)
// 288 Crown Street (numeric-only street number)
// 1085-1087 Boylston St
// ^\s*((street number)\s+(street name)|(street number)|(street name)\s*$
const LEFT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP = /^\s*((?![0-9]+(?=st|nd|rd|th))([0-9]+[a-z]*)\s+(.*)|((?![0-9]+(?=st|nd|rd|th))[0-9]+[a-z]*)|(.*))\s*$/i

function formatStreet(address) {
  return `${address.houseNumber} ${address.streetName}`.trim()
}

function getHouseNumber(userAddress) {
  if (!userAddress) {
    return ''
  }

  const matches = userAddress.match(
    LEFT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP
  )
  if (matches === null) {
    return ''
  }
  return (matches[2] || matches[4] || '').trim()
}

function getStreet(userAddress) {
  if (!userAddress) {
    return ''
  }

  const matches = userAddress.match(
    LEFT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP
  )
  if (matches === null) {
    return ''
  }
  return (matches[3] || matches[5] || '').trim()
}

export const streetFunctions = {
  formatStreet,
  getHouseNumber,
  getStreet,
}
