const RIGHT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP = /^((.*)\s+([0-9]+[a-z]*)|\s*([0-9]+[a-z]*)|(.*))\s*$/i
const RIGHT_SIDE_STREET_NUMBER_STREET_WITH_SLASH_COMPONENTS_REGEXP = /^((.*)\s+([0-9]+[/\da-zA-Z]*)|\s*([0-9]+[/\da-zA-Z]*)|(.*))\s*$/i

function formatStreet(address) {
  return `${address.streetName} ${address.houseNumber}`.trim()
}

const getHouseNumber = (streetNumberRegex) => (userAddress) => {
  if (!userAddress) {
    return ''
  }

  const matches = userAddress.match(streetNumberRegex)
  if (matches === null) {
    return ''
  }
  return (matches[4] || matches[3] || '').trim()
}

const getStreet = (streetNumberRegex) => (userAddress) => {
  if (!userAddress) {
    return ''
  }

  const matches = userAddress.match(streetNumberRegex)
  if (matches === null) {
    return ''
  }
  return (matches[2] || matches[5] || '').trim()
}

export const streetFunctions = {
  formatStreet,
  getHouseNumber: getHouseNumber(
    RIGHT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP
  ),
  getStreet: getStreet(RIGHT_SIDE_STREET_NUMBER_STREET_COMPONENTS_REGEXP),
}

export const streetFunctionsWithSlash = {
  formatStreet,
  getHouseNumber: getHouseNumber(
    RIGHT_SIDE_STREET_NUMBER_STREET_WITH_SLASH_COMPONENTS_REGEXP
  ),
  getStreet: getStreet(
    RIGHT_SIDE_STREET_NUMBER_STREET_WITH_SLASH_COMPONENTS_REGEXP
  ),
}
