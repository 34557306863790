import {
  ADDRESS_FIELD_INVALID,
  ADDRESS_FIELD_VERIFIED,
  ADDRESS_FIELD_UNVERIFIED,
} from '../../../model/address'

import { validateAddressField } from '../../../model/addressValidation'

function getFieldStatus(lastVerifiedAddress, address, fieldName) {
  const validateResult = validateAddressField(address, fieldName)
  if (
    validateResult.isValid &&
    address[fieldName] === lastVerifiedAddress[fieldName]
  ) {
    return { status: ADDRESS_FIELD_VERIFIED }
  } else {
    return {
      status: validateResult.isValid
        ? ADDRESS_FIELD_UNVERIFIED
        : ADDRESS_FIELD_INVALID,
      reason: validateResult.reason,
      shouldShowError: true,
    }
  }
}

function verifyAddressField(state, fieldName) {
  return {
    ...state,
    fieldStatuses: {
      ...state.fieldStatuses,
      [fieldName]: getFieldStatus(
        state.lastKnownVerifiedAddress,
        state.address,
        fieldName
      ),
    },
  }
}

function hideValidationErrorForField(state, ...fieldNames) {
  // We always need to return a new instance of fieldStatuses in the new state.
  // Otherwise react-redux connect's shallow compare will not update the component.
  //
  // This will result in re-rendering every time even when status hasn't really changed.
  // If we ever run into performance issues, we can use 'reconnect' library to cache the associated selector.
  const newState = { ...state, fieldStatuses: { ...state.fieldStatuses } }

  for (let fieldName of fieldNames) {
    newState.fieldStatuses[fieldName]['shouldShowError'] = false
  }

  return newState
}

export { getFieldStatus, verifyAddressField, hideValidationErrorForField }
