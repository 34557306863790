import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

const composeEnhancers = composeWithDevTools({
  name: 'AddressAutocomplete Widget',
})

export default () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
}
