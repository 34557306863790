import { addEventListener, removeEventListener } from './bindEvents'

function inputToStateBinding(input, onChange, valueAccessor) {
  let currentValue = input.value

  return {
    handleInput: (event) => {
      // notify listeners about the new requested state and hold off the actual change until a state change comes.
      onChange(event.target.value)
      input.value = currentValue
    },

    handleStateUpdate: (viewState) => {
      // If the value was changed in the viewState and it's different from what we think about the viewState, we update
      const updatedValue = valueAccessor(viewState)
      if (updatedValue !== currentValue) {
        currentValue = updatedValue
        input.value = currentValue
      }
    },
  }
}

export default function bindInputToState(
  context,
  input,
  onChange,
  valueAccessor
) {
  const binding = inputToStateBinding(input, onChange, valueAccessor)
  const { subscribe } = context

  function handleInput(event) {
    binding.handleInput(event)
  }

  subscribe((viewState) => binding.handleStateUpdate(viewState))

  addEventListener(input, 'input', handleInput)
  binding.unbind = () => {
    removeEventListener(input, 'input', handleInput)
  }

  return binding
}
