import {
  ADDRESS_FIELD_UNVERIFIED,
  ADDRESS_FIELD_VERIFIED,
  ADDRESS_VERIFIED,
  ADDRESS_UNVERIFIED,
  ADDRESS_INCOMPLETE,
} from '../../model/address'

export const getFormStatus = (state) => {
  const fieldStatuses = getFieldStatuses(state)

  if (!state.addressForm.currentCountryDetails.containsState) {
    delete fieldStatuses['state']
  }

  const isAddressVerified = Object.values(fieldStatuses).every(
    (value) => value.status === ADDRESS_FIELD_VERIFIED
  )

  const isAddressUnverified = Object.values(fieldStatuses).every(
    (value) =>
      value.status === ADDRESS_FIELD_UNVERIFIED ||
      value.status === ADDRESS_FIELD_VERIFIED
  )

  if (isAddressVerified) {
    return ADDRESS_VERIFIED
  } else if (isAddressUnverified) {
    return ADDRESS_UNVERIFIED
  } else {
    return ADDRESS_INCOMPLETE
  }
}

export const getAddress = (state) => state.addressForm.address
export const getFieldStatuses = (state) => state.addressForm.fieldStatuses
export const getLastKnownVerifiedAddress = (state) =>
  state.addressForm.lastKnownVerifiedAddress
export const getContainsState = (state) =>
  state.addressForm.currentCountryDetails.containsState
export const getPreferredStreetFormat = (state) =>
  state.addressForm.currentCountryDetails.preferredStreetFormat
export const getVisibleStreetFormat = (state) =>
  state.addressForm.currentCountryDetails.visibleStreetFormat
export const getFormMetadata = (state) => state.addressForm.formMetadata
export const getValidationStatus = (state) => ({
  formStatus: getFormStatus(state),
  fieldStatuses: getFieldStatuses(state),
})
export const getIsAutocompleteAllowed = (state) =>
  state.addressForm.currentCountryDetails.isAutocompleteAllowed
export const getIsVerificationPending = (state) =>
  state.addressForm.isVerificationPending
export const getHasStreetChangedSinceValidation = (state) =>
  getAddress(state).streetName !== getLastKnownVerifiedAddress(state).streetName
