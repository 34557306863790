export const PROMISE_CANCELED = 'PROMISE_CANCELED'
export const cancelablePromise = (promise) => {
  let outerReject
  let isCanceled = false
  const outerPromise = new Promise((resolve, reject) => {
    outerReject = reject
    promise
      .then((...args) => {
        if (!isCanceled) {
          resolve(...args)
        }
      })
      .catch((...args) => reject(...args))
  })

  return {
    promise: outerPromise,
    cancel: () => {
      isCanceled = true
      outerReject(PROMISE_CANCELED)
    },
  }
}

export const singleConcurrentRequest = (func) => {
  let pending = null

  const call = async (...args) => {
    if (pending) {
      pending.cancel()
    }
    pending = cancelablePromise(func(...args))
    const result = await pending.promise
    pending = null
    return result
  }

  call.cancelPending = () => {
    if (pending) {
      pending.cancel()
      pending = null
    }
  }

  return call
}
