import React from 'react'

export default function HowToErrorVisuals() {
  return (
    <>
      You can use {'<Error />'} component to insert placeholders where errors
      for given address fields will be shown. The fieldKeys property can contain
      any field names that support validation, delimited by spaces. The element
      will automatically display validation errors for all specified fields, but
      only one at the same time. The priority of error messages is determined by
      order in which the field names are specified.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-jsx card-body">
            {`<ErrorVisual fieldKeys={["streetName", "houseNumber"]} />`}
          </code>
        </pre>
      </div>
      You may format the errors using the FormatError property. It accepts a
      React component and expects it to render props.children, which contains
      the error message.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-jsx card-body">
            {`<ErrorVisual
    fieldKeys={["streetName", "houseNumber"]}
    FormatError={(props) => <label className="error">{props.children}</label>}
  />
  `}
          </code>
        </pre>
      </div>
      Sometimes it is useful to show place holder content when there is no
      error. You can do it as shown in the folowing example, including custom
      formating.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-jsx card-body">
            {`<ErrorVisual
    fieldKeys={"streetName"}
    placeHolder="Street Name"
    FormatPlaceholder={(props) => (
      <label>
        <small>{props.children}</small>
      </label>
    )}
  />
  `}
          </code>
        </pre>
      </div>
      A handy trick is to wrap it everything into a custom component like this.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-jsx card-body">
            {`function CustomErrorVisual(props) {
    return (
      <ErrorVisual
        {...props}
        FormatError={(props) => <label className="error">{props.children}</label>}
        FormatPlaceholder={(props) => (
          <label>
            <small>{props.children}</small>
          </label>
        )}
      />
    )
  }

  // Usage example
  <CustomErrorVisual fieldKeys={["streetName", "houseNumber"]} />`}
          </code>
        </pre>
      </div>
      Default error messages are hard coded and written in English. You can
      override and localize like so.
      <div className="card mb-3 mt-3">
        <pre>
          <code className="language-jsx card-body">
            {`const errorMessageMap = {
    streetName: 'Straat is een verplicht veld',
    houseNumber: 'Huisnummer is een verplicht veld',
    city: 'Stad is een verplicht veld',
    state: 'Staat is een verplicht veld',
    postalCode: {
      DEFAULT: 'Postcode is een verplicht veld',
      REASON_INVALID_FORMAT: 'Postcode moet in een geldig formaat zijn',
    },
  }

  <AddressAutocomplete errorMessageMap={errorMessageMap}>

    <ErrorVisual fieldKeys="city" />

  </AddressAutocomplete>
`}
          </code>
        </pre>
      </div>
    </>
  )
}
