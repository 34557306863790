import { getCountryFunctions } from './countries'
import { REASON_MUST_NOT_BE_EMPTY, REASON_INVALID_FORMAT } from './address'

function validateWithReason(validatorResult, reasonIfFailed) {
  if (validatorResult === false) {
    return { isValid: false, reason: reasonIfFailed }
  } else {
    return { isValid: true }
  }
}

const validateNonEmpty = (value, countryCode, field) => {
  const { getRequiredFields } = getCountryFunctions(countryCode)
  return validateWithReason(
    !getRequiredFields().includes(field) || value !== '',
    REASON_MUST_NOT_BE_EMPTY
  )
}

const validatePostalCode = (value, countryCode) => {
  const { validatePostalCode } = getCountryFunctions(countryCode)
  let result = false
  if (countryCode) {
    result = validatePostalCode(value)
  }
  return validateWithReason(result, REASON_INVALID_FORMAT)
}

const allOf = (...validatorFunctions) => {
  return function(value, countryCode, field) {
    let isValid = true
    let reason = null
    for (let validatorFunction of validatorFunctions) {
      const validatorResult = validatorFunction(value, countryCode, field)
      isValid = isValid && validatorResult.isValid
      if (!isValid) {
        reason = validatorResult.reason
        break
      }
    }
    return { isValid, reason }
  }
}

const fieldValidatorFunctions = {
  countryCode: validateNonEmpty,
  streetName: validateNonEmpty,
  houseNumber: validateNonEmpty,
  city: validateNonEmpty,
  state: validateNonEmpty,
  postalCode: allOf(validateNonEmpty, validatePostalCode),
}

export const validateAddressField = (address, field) => {
  const validatorFunction = fieldValidatorFunctions[field]

  if (!validatorFunction) {
    return true
  } else {
    return validatorFunction(address[field], address.countryCode, field)
  }
}
