import {
  convertGooglePlaceToAddress,
  addressContainsState,
  preferredStreetFormat,
  getRequiredFieldsNoState as getRequiredFields,
} from './eu'
import { streetFunctionsWithSlash } from '../rightSideHouseNumber'

function validatePostalCode(value) {
  return value.trim().match(/^\d{5}$/) !== null
}

export const DE = {
  countryCode: 'DE',
  name: 'Germany',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctionsWithSlash.formatStreet
    ),
    ...streetFunctionsWithSlash,
    addressContainsState,
    preferredStreetFormat,
    getRequiredFields,
    isAutocompleteAllowed: () => true,
  },
}
