import React from 'react'
import { AddressAutocomplete, ErrorVisual } from '../../AddressAutocomplete'

function CustomErrorVisual(props) {
  return (
    <ErrorVisual
      {...props}
      FormatError={(props) => <label className="error">{props.children}</label>}
      FormatPlaceholder={(props) => (
        <label>
          <small>{props.children}</small>
        </label>
      )}
    />
  )
}

export default class SmartSwitchingForm extends React.Component {
  state = {}

  onFormBound = (inst) => {
    this.validateForm = inst.validateForm

    // Expose validation function for automated testing
    if (window) {
      window._validateTestForm = this.validateForm
    }
  }

  onSubmit = async () => {
    try {
      const validationStatus = await this.validateForm()

      this.setState({
        formStatus: validationStatus.formStatus,
      })

      const formValues = Array.from(
        document.querySelectorAll(
          '.demoAddressForm input, .demoAddressForm select'
        )
      ).map((el) => el.value)

      // Submit values
      console.log('Will submit', formValues)
    } catch (e) {
      if (e === this.validateForm.CANCELED) {
        // Everything OK
        console.log('canceled')
      } else {
        console.error(e)
      }
    }
  }

  onValidationStatusChanged = (validationStatus) => {
    this.setState({
      formStatus: validationStatus.formStatus,
    })
  }

  onCountryChanged = () => {
    this.setState({
      formStatus: '',
    })
  }

  render() {
    return (
      <div className="container demoAddressForm">
        <AddressAutocomplete
          onValidationStatusChanged={this.onValidationStatusChanged}
          onFormBound={this.onFormBound}
        >
          <div className="row" key="country" data-address-role="country">
            <div className="col-md-3" key="col1">
              <label className="required">Country</label>
            </div>

            <div className="col-md-7 mb-3" key="col2">
              <select
                className="form-control"
                placeholder=""
                defaultValue="NL"
                id="country-selector"
                onChange={this.onCountryChanged}
              >
                <option value="BE" key="BE">
                  Belgium
                </option>
                <option value="CZ" key="CZ">
                  Czech Republic
                </option>
                <option value="DE" key="DE">
                  Germany
                </option>
                <option value="IT" key="IT">
                  Italy
                </option>
                <option value="NL" key="NL">
                  Netherlands
                </option>
                <option value="GB" key="GB">
                  United Kingdom
                </option>
                <option value="US" key="US">
                  United States
                </option>
                <option value="IE" key="IE">
                  Ireland
                </option>
              </select>
            </div>
          </div>

          <div className="row companyField" key="company">
            <div className="col-md-3" key="col1">
              <label>Company</label>
            </div>
            <div className="col-md-7 mb-3" key="col2">
              <input className="form-control" placeholder="" />
            </div>
          </div>

          <div
            className="row"
            key="street"
            data-address-role="streetNameAndHouseNumber"
          >
            <div className="col-md-3" key="col1">
              <label className="required">Street</label>
            </div>

            <div className="col-md-7 mb-3" key="col2">
              <input
                className="form-control"
                placeholder="Start typing your address..."
                key="input"
              />

              <CustomErrorVisual
                key="errorVisual"
                fieldKeys={['streetName', 'houseNumber']}
              />
            </div>
          </div>

          <div
            className="row"
            key="streeteu"
            data-address-role="streetNameAndHouseNumberGroup"
          >
            <div className="col-md-3" key="col1">
              <label className="required">Street</label>
            </div>

            <div className="col-md-5" key="col2">
              <input
                data-address-role="streetName"
                className="form-control"
                placeholder="Start typing your address..."
                key="input"
              />

              <CustomErrorVisual
                key="errorVisual"
                fieldKeys={['streetName']}
                placeHolder="Street name"
              />
            </div>

            <div className="col-md-2" key="col3">
              <input
                data-address-role="houseNumber"
                className="form-control"
                placeholder="Number"
                key="input"
              />
              <CustomErrorVisual
                key="errorVisual"
                fieldKeys={['houseNumber']}
                placeHolder="House nr."
              />
            </div>
          </div>

          <div className="row" key="city" data-address-role="city">
            <div className="col-md-3" key="col1">
              <label className="required">City</label>
            </div>

            <div className="col-md-7 mb-3" key="col2">
              <input key="input" className="form-control" placeholder="" />
              <CustomErrorVisual key="errorVisual" fieldKeys="city" />
            </div>
          </div>

          <div data-address-role="state" key="state">
            <div className="row" key="state">
              <div className="col-md-3" key="col1">
                <label className="required">State</label>
              </div>

              <div className="col-md-7 mb-3" key="col2">
                <input key="input" className="form-control" placeholder="" />
                <CustomErrorVisual key="errorVisual" fieldKeys="state" />
              </div>
            </div>
          </div>

          <div className="row" key="postalcode" data-address-role="postalCode">
            <div className="col-md-3" key="col1">
              <label className="required">ZIP</label>
            </div>

            <div className="col-md-7 mb-3" key="col2">
              <input key="input" className="form-control" placeholder="" />
              <CustomErrorVisual key="errorVisual" fieldKeys="postalCode" />
            </div>
          </div>
        </AddressAutocomplete>

        <hr className="mb-4" />

        <div className="row">
          <button
            id="submitButton"
            className="btn btn-primary btn-lg btn-block"
            onClick={this.onSubmit}
          >
            Submit
          </button>
        </div>

        {this.state.formStatus ? (
          <div className="formStatus">
            {'Form status is'} {this.state.formStatus}
          </div>
        ) : null}
      </div>
    )
  }
}
