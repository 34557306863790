import React from 'react'
import { AddressAutocomplete } from '../../AddressAutocomplete'

let validateForm
const onFormBound = (inst) => (validateForm = inst.validateForm)

async function onSubmit() {
  console.log(await validateForm())
}

export default function USForm() {
  return (
    <div className="container demoAddressForm">
      <AddressAutocomplete onFormBound={onFormBound}>
        <div className="row" key="row1">
          <div className="col-md-3" key="col1">
            <label className="required">Country</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <select
              data-address-role="country"
              className="form-control"
              placeholder=""
            >
              <option value="BE" key="BE">
                Belgium
              </option>
              <option value="CZ" key="CZ">
                Czech Republic
              </option>
              <option value="DE" key="DE">
                Germany
              </option>
              <option value="IT" key="IT">
                Italy
              </option>
              <option value="NL" key="NL">
                Netherlands
              </option>
              <option value="GB" key="GB">
                United Kingdom
              </option>
              <option value="US" key="US">
                United States
              </option>
              <option value="IE" key="IE">
                Ireland
              </option>
            </select>
          </div>
        </div>

        <div className="row" key="row2">
          <div className="col-md-3" key="col1">
            <label className="required">Address</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              data-address-role="streetNameAndHouseNumber"
              className="form-control"
              key="input"
            />
          </div>
        </div>

        <div className="row" key="row4">
          <div className="col-md-3" key="col1">
            <label className="required">City</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              data-address-role="city"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>

        <div className="row" key="state" data-address-role="state">
          <div className="col-md-3" key="col1">
            <label className="required">State</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input className="form-control" placeholder="" />
          </div>
        </div>

        <div className="row" key="row5">
          <div className="col-md-3" key="col1">
            <label className="required">ZIP</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              data-address-role="postalCode"
              className="form-control"
              placeholder=""
            />
          </div>
        </div>
      </AddressAutocomplete>

      <hr className="mb-4" />

      <div className="row">
        <button className="btn btn-primary btn-lg btn-block" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}
