import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import createStore from '../../state/createStore'
import { SUPPORTED_COUNTRIES } from '../countries'
import { singleConcurrentRequest, PROMISE_CANCELED } from '../promiseTools'
import { deepTraverseChildren } from './traversal'
import collectFormMetadata from './metadata'
import { updateErrorVisuals, hideAllErrors } from './errorVisuals'
import { selectors, actions } from '../../state/addressForm'
import * as autocomplete from '../../state/autocomplete'

import * as fieldNames from './fieldNames'
import {
  bindCountrySelector,
  bindStreetName,
  bindHouseNumber,
  bindStreetAndHouse,
  bindStreetAndHouseGroup,
  bindCity,
  bindState,
  bindPostalCode,
} from './bindFields'
import { getPlacePredictions } from '../googlePlacesApi'

const BIND_FUNCTIONS_REGISTRY = {
  [fieldNames.COUNTRY]: bindCountrySelector,
  [fieldNames.STREET_NAME]: bindStreetName,
  [fieldNames.HOUSE_NUMBER]: bindHouseNumber,
  [fieldNames.STREET_AND_HOUSE_UNIFIED]: bindStreetAndHouse,
  [fieldNames.STREET_AND_HOUSE_GROUP]: bindStreetAndHouseGroup,
  [fieldNames.CITY]: bindCity,
  [fieldNames.STATE]: bindState,
  [fieldNames.POSTAL_CODE]: bindPostalCode,
}

function handleVerificationError(e) {
  if (e === PROMISE_CANCELED) {
    // ignore
  } else {
    console.error(e)
  }
}

function bindElement(context, element, props) {
  const dataAddressRole = element.getAttribute('data-address-role')
  if (!dataAddressRole) {
    return
  }

  const bindFunction = BIND_FUNCTIONS_REGISTRY[dataAddressRole]
  if (bindFunction) {
    return bindFunction(context, element, props)
  }
}

function mapStoreStateToViewState(storeState) {
  return {
    address: selectors.getAddress(storeState),
    visibleStreetFormat: selectors.getVisibleStreetFormat(storeState),
    containsState: selectors.getContainsState(storeState),
    isAutocompleteAllowed: selectors.getIsAutocompleteAllowed(storeState),
  }
}

function mapActionsToViewProps(dispatch) {
  return {
    onCountryChanged: (countryCode) =>
      dispatch(actions.changeCountryCode(countryCode)),

    onStreetChanged: (street) => {
      dispatch(actions.changeStreet(street))
      dispatch(actions.restartAddressVerificationIfPending()).catch(
        handleVerificationError
      )
    },

    onStreetAndHouseNumberChanged: (streetAndHouseNumber) => {
      dispatch(actions.changeStreetAndHouseNumber(streetAndHouseNumber))
      dispatch(actions.restartAddressVerificationIfPending()).catch(
        handleVerificationError
      )
    },

    onHouseNumberChanged: (houseNumber) => {
      dispatch(actions.changeHouseNumber(houseNumber))
      dispatch(actions.restartAddressVerificationIfPending()).catch(
        handleVerificationError
      )
    },

    onCityChanged: (city) => {
      dispatch(actions.changeCity(city))
      dispatch(actions.restartAddressVerificationIfPending()).catch(
        handleVerificationError
      )
    },

    onStateChanged: (state) => dispatch(actions.changeState(state)),

    onPostalCodeChanged: (postalCode) => {
      dispatch(actions.changePostalCode(postalCode))
      dispatch(actions.restartAddressVerificationIfPending()).catch(
        handleVerificationError
      )
    },

    onFieldsTouched: (...fieldNames) => {
      dispatch(actions.validateFields(...fieldNames))
      if (fieldNames.includes('houseNumber')) {
        dispatch(actions.verifyAddress()).catch(handleVerificationError)
      }
    },

    onHintsKeyDown: (event) => {
      dispatch(autocomplete.actions.processKey(event))
    },

    onHintsTextChanged: (value) => {
      dispatch(autocomplete.actions.fetchPredictions(value))
    },

    onHintsBlur: () => {
      getPlacePredictions.cancelPending()
      dispatch(autocomplete.actions.hidePredictions())
    },
  }
}

function subscribeFormToStore(root, store, supportedCountries) {
  const storeSubscriptions = []
  const dispatch = store.dispatch
  const context = {
    store,
    subscribe: (cb) => {
      const unsubscribe = store.subscribe(() =>
        cb(mapStoreStateToViewState(store.getState()))
      )
      storeSubscriptions.push(unsubscribe)
    },
  }
  const props = {
    supportedCountries,
    ...mapActionsToViewProps(dispatch),
  }

  const unbindFunctions = []
  deepTraverseChildren(root, (child) => {
    unbindFunctions.push(bindElement(context, child, props))
  })

  return () => {
    storeSubscriptions.forEach((unsubscribe) => {
      unsubscribe()
    })
    unbindFunctions.forEach((unbind) => {
      if (typeof unbind === 'function') {
        unbind()
      }
    })
  }
}

function getValidationStatus(store) {
  return selectors.getValidationStatus(store.getState())
}

function validationUpdateSubscription(store) {
  let unsubscribeStore = () => {}
  let subscriptions = []

  function subscribe(callback) {
    subscriptions.push(callback)
  }

  function unsubscribe() {
    subscriptions = []
    unsubscribeStore()
  }

  let lastStatus = getValidationStatus(store)

  unsubscribeStore = store.subscribe(() => {
    const newStatus = getValidationStatus(store)
    if (!isEqual(newStatus, lastStatus)) {
      lastStatus = cloneDeep(newStatus)
      subscriptions.forEach((cb) => cb(lastStatus))
    }
  })

  return {
    subscribe,
    unsubscribe,
  }
}

export default function mountAutocompleteToDom(
  element,
  { supportedCountries = SUPPORTED_COUNTRIES, errorMessageMap = undefined } = {}
) {
  const store = createStore()

  const formMetadata = collectFormMetadata(element, supportedCountries)

  const unsubscribeForm = subscribeFormToStore(
    element,
    store,
    supportedCountries
  )
  store.dispatch(actions.setFormMetadata(formMetadata))

  const validationUpdates = validationUpdateSubscription(store)

  if (formMetadata.shouldUpdateErrorVisuals) {
    hideAllErrors(element)
    validationUpdates.subscribe((validationStatus) => {
      updateErrorVisuals(validationStatus, { root: element, errorMessageMap })
    })
  }
  const validateForm = singleConcurrentRequest(() => {
    if (!selectors.getIsVerificationPending(store.getState())) {
      store.dispatch(actions.validateForm())
      return Promise.resolve(getValidationStatus(store))
    } else {
      return new Promise((resolve) => {
        const unsubscribe = store.subscribe(() => {
          if (!selectors.getIsVerificationPending(store.getState())) {
            unsubscribe()
            resolve(getValidationStatus(store))
          }
        })
      })
    }
  })
  validateForm.CANCELED = PROMISE_CANCELED
  return {
    store,
    formMetadata,
    validateForm,
    updateErrorVisuals: (selector) =>
      updateErrorVisuals(selector, getValidationStatus(store), {
        root: element,
      }),
    subscribe: (callback) => {
      validationUpdates.subscribe(callback)
    },
    unmount: () => {
      validationUpdates.unsubscribe()
      unsubscribeForm()
    },
  }
}
