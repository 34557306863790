import { matches } from '../domTools'

function _deepTraverseChildren(root, visitor) {
  for (let child of root.children) {
    _deepTraverseChildren(child, visitor)
    visitor(child)
  }
}

function deepTraverseChildren(root, visitor) {
  if (root) {
    visitor(root)
    _deepTraverseChildren(root, visitor)
  }
}

function findFirstAndApply(root, selector, action) {
  if (matches(root, selector)) {
    return action(root)
  }

  const first = root.querySelector(selector)
  if (first) {
    return action(first)
  }
}

export { deepTraverseChildren, findFirstAndApply }
