import { STREET_FORMAT } from '../address'
import {
  addressContainsState,
  getRequiredFieldsNoState as getRequiredFields,
} from './eu'
import { getLongNameOf, getShortNameOf } from '../googlePlacesApi'
import { streetFunctions } from '../leftSideHouseNumber'

const UK_POSTCODE_REGULAR_EXPRESSIONS = [
  // AA9A 9AA
  /^[A-Z][A-Z][0-9][A-Z] [0-9][A-Z][A-Z]$/,
  // A9A 9AA
  /^[A-Z][0-9][A-Z] [0-9][A-Z][A-Z]$/,
  // A9 9AA
  /^[A-Z][0-9] [0-9][A-Z][A-Z]$/,
  // A99 9AA
  /^[A-Z][0-9][0-9] [0-9][A-Z][A-Z]$/,
  // AA9 9AA
  /^[A-Z][A-Z][0-9] [0-9][A-Z][A-Z]$/,
  // AA99 9AA
  /^[A-Z][A-Z][0-9][0-9] [0-9][A-Z][A-Z]$/,
]

export function validatePostalCode(value) {
  if (!value) {
    return false
  }
  return (
    UK_POSTCODE_REGULAR_EXPRESSIONS.find(
      (regexp) => value.match(regexp) !== null
    ) !== undefined
  )
}

export function convertGooglePlaceToAddress(formatStreet) {
  return function convertGooglePlaceToAddressInternal(place) {
    const getShortName = (type) => getShortNameOf(type, place)
    const getLongName = (type) => getLongNameOf(type, place)

    const countryCode = getShortName('country').toUpperCase()
    const state = getLongName('administrative_area_level_1')
    const city =
      getLongName('postal_town') || getLongName('administrative_area_level_2')
    const houseNumber = getLongName('street_number')
    const streetName = getShortName('route')
    const postalCode = getShortName('postal_code')
    const streetAndHouseNumber = formatStreet({ streetName, houseNumber })

    return {
      countryCode,
      state,
      city,
      streetAndHouseNumber,
      streetName,
      houseNumber,
      postalCode,
    }
  }
}

export const GB = {
  countryCode: 'GB',
  name: 'United Kingdom',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState,
    preferredStreetFormat: () => STREET_FORMAT.SINGLE_FIELD,
    isAutocompleteAllowed: () => true,
    getRequiredFields,
  },
}
