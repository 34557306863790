import { STREET_FORMAT } from '../address'
import { getLongNameOf, getShortNameOf } from '../googlePlacesApi'

export function convertGooglePlaceToAddress(formatStreet) {
  return function convertGooglePlaceToAddressInternal(place) {
    const getShortName = (type) => getShortNameOf(type, place)
    const getLongName = (type) => getLongNameOf(type, place)

    const countryCode = getShortName('country').toUpperCase()
    const state = getLongName('administrative_area_level_1')
    const city = getLongName('locality') || getLongName('sublocality_level_1')
    const houseNumber = getLongName('street_number')
    const streetName = getShortName('route')
    const postalCode = getShortName('postal_code')
    const streetAndHouseNumber = formatStreet({ streetName, houseNumber })

    return {
      countryCode,
      state,
      city,
      streetAndHouseNumber,
      streetName,
      houseNumber,
      postalCode,
    }
  }
}

export const addressContainsState = () => false
export const preferredStreetFormat = () => STREET_FORMAT.SEPARATE_HOUSE_NUMBER
export const getRequiredFieldsNoState = () => [
  'countryCode',
  'streetName',
  'houseNumber',
  'city',
  'postalCode',
]
export const getRequiredFieldsWithState = () => [
  'countryCode',
  'streetName',
  'houseNumber',
  'city',
  'state',
  'postalCode',
]
