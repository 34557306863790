import React from 'react'

import '../DemoForm.css'
import ApiLoader from './ApiLoader'

export default class DemoFormPlain extends React.Component {
  form = null

  apiLoaded = () => {
    if (window.cimpressAddressAutocomplete && !this.form) {
      this.form = window.cimpressAddressAutocomplete.mountAutocomplete(
        document.querySelector('.demoAddressForm')
      )
      this.form.subscribe(this.updateFormValidationStatus)

      // expose for testing
      window._validateTestForm = this.form.validateForm
    }
  }

  updateFormValidationStatus = (status) => {
    document.querySelector(
      '.formStatus'
    ).innerHTML = `Form status is ${status.formStatus}`
  }

  onCountryChanged = () => {
    document.querySelector('.formStatus').innerHTML = ''
  }

  onSubmit = async () => {
    try {
      const validationStatus = await this.form.validateForm()
      this.updateFormValidationStatus(validationStatus)

      // Submit values
      const formValues = Array.from(
        document.querySelectorAll(
          '.demoAddressForm input, .demoAddressForm select'
        )
      ).map((el) => el.value)

      document.querySelector('#output').innerHTML = JSON.stringify(
        { formValues, validationStatus },
        null,
        2
      )
    } catch (e) {
      if (e === this.form.validateForm.CANCELED) {
        // Everything OK
        console.log('canceled')
      } else {
        console.error(e)
      }
    }
  }

  render() {
    return (
      <>
        <ApiLoader onApiLoaded={this.apiLoaded}>
          <div className="container demoAddressForm">
            <h2>Address form</h2>
            <hr />
            <div className="row" key="country" data-address-role="country">
              <div className="col-md-3" key="col1">
                <label className="required">Country</label>
              </div>

              <div className="col-md-7 mb-3" key="col2">
                <select
                  id="country-selector"
                  className="form-control"
                  placeholder=""
                  defaultValue="NL"
                  onChange={this.onCountryChanged}
                >
                  <option value="BE" key="BE">
                    Belgium
                  </option>
                  <option value="CZ" key="CZ">
                    Czech Republic
                  </option>
                  <option value="DE" key="DE">
                    Germany
                  </option>
                  <option value="IT" key="IT">
                    Italy
                  </option>
                  <option value="NL" key="NL">
                    Netherlands
                  </option>
                  <option value="GB" key="GB">
                    United Kingdom
                  </option>
                  <option value="US" key="US">
                    United States
                  </option>
                  <option value="IE" key="IE">
                    Ireland
                  </option>
                  <label className="error" data-error-for-fields="country" />
                </select>
              </div>
            </div>

            <div className="row companyField" key="company">
              <div className="col-md-3" key="col1">
                <label>Company</label>
              </div>
              <div className="col-md-7 mb-3" key="col2">
                <input className="form-control" placeholder="" />
              </div>
            </div>

            <div
              className="row"
              key="street"
              data-address-role="streetNameAndHouseNumber"
            >
              <div className="col-md-3" key="col1">
                <label className="required">Street</label>
              </div>

              <div className="col-md-7 mb-3" key="col2">
                <input
                  className="form-control"
                  placeholder="Start typing your address..."
                  key="input"
                />
                <label
                  className="error"
                  data-error-for-fields="streetName houseNumber"
                />
              </div>
            </div>

            <div
              className="row"
              key="streeteu"
              data-address-role="streetNameAndHouseNumberGroup"
            >
              <div className="col-md-3" key="col1">
                <label className="required">Street</label>
              </div>

              <div className="col-md-5" key="col2">
                <input
                  data-address-role="streetName"
                  className="form-control"
                  placeholder="Start typing your address..."
                  key="input"
                />
                <div data-error-for-fields="streetName">
                  <label className="error" data-error-role="content" />
                  <div data-error-role="placeHolder">
                    <label>
                      <small>Street name</small>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-2" key="col3">
                <input
                  data-address-role="houseNumber"
                  className="form-control"
                  placeholder="Number"
                  key="input"
                />
                <div data-error-for-fields="houseNumber">
                  <label className="error" data-error-role="content" />
                  <div data-error-role="placeHolder">
                    <label>
                      <small>House nr.</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" key="city" data-address-role="city">
              <div className="col-md-3" key="col1">
                <label className="required">City</label>
              </div>

              <div className="col-md-7 mb-3" key="col2">
                <input key="input" className="form-control" placeholder="" />
                <label className="error" data-error-for-fields="city" />
              </div>
            </div>

            <div data-address-role="state" key="state">
              <div className="row" key="state">
                <div className="col-md-3" key="col1">
                  <label className="required">State</label>
                </div>

                <div className="col-md-7 mb-3" key="col2">
                  <input key="input" className="form-control" placeholder="" />
                  <label className="error" data-error-for-fields="state" />
                </div>
              </div>
            </div>

            <div
              className="row"
              key="postalcode"
              data-address-role="postalCode"
            >
              <div className="col-md-3" key="col1">
                <label className="required">ZIP</label>
              </div>

              <div className="col-md-7 mb-3" key="col2">
                <input key="input" className="form-control" placeholder="" />
                <label className="error" data-error-for-fields="postalCode" />
              </div>
            </div>

            <hr className="mb-4" />

            <div className="row">
              <button
                id="submitButton"
                className="btn btn-primary btn-lg btn-block"
                onClick={this.onSubmit}
              >
                Submit
              </button>
            </div>

            <div className="formStatus" />

            <hr className="mb-4" />

            <pre id="output" />
          </div>
        </ApiLoader>
      </>
    )
  }
}
