import {
  preferredStreetFormat,
  getRequiredFieldsWithState as getRequiredFields,
} from './eu'
import { streetFunctionsWithSlash } from '../rightSideHouseNumber'
import { getLongNameOf, getShortNameOf } from '../googlePlacesApi'

function validatePostalCode(value) {
  return value.trim().match(/^\d{5}$/) !== null
}

function convertGooglePlaceToAddress(formatStreet) {
  return function convertGooglePlaceToAddressInternal(place) {
    const getShortName = (type) => getShortNameOf(type, place)
    const getLongName = (type) => getLongNameOf(type, place)

    const countryCode = getShortName('country').toUpperCase()
    const state = getShortName('administrative_area_level_2')
    const city =
      getLongName('locality') || getLongName('administrative_area_level_3')
    const houseNumber = getLongName('street_number')
    const streetName = getShortName('route')
    const postalCode = getShortName('postal_code')
    const streetAndHouseNumber = formatStreet({ streetName, houseNumber })

    return {
      countryCode,
      state,
      city,
      streetAndHouseNumber,
      streetName,
      houseNumber,
      postalCode,
    }
  }
}

export const IT = {
  countryCode: 'IT',
  name: 'Italy',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctionsWithSlash.formatStreet
    ),
    ...streetFunctionsWithSlash,
    addressContainsState: () => true,
    preferredStreetFormat,
    isAutocompleteAllowed: () => true,
    getRequiredFields,
  },
}
