/* global Prism */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react'

import ReactDemo from './React'
import ReactQuickStart from './React/Docs/QuickStart'
import ReactErrorVisuals from './React/Docs/ErrorVisuals'

import PlainJsDemo from './PlainJs'
import PlainJsQuickStart from './PlainJs/Docs/QuickStart'
import PlainJsErrorVisuals from './PlainJs/Docs/ErrorVisuals'
import Faq from './FAQ'

import DocFields from './DocFields'

function UpButton() {
  return (
    <>
      <a href="#" className="btn btn-primary btn-sm float-right">
        Up
      </a>
    </>
  )
}

function Headline(props) {
  return (
    <>
      <a id={props.id}></a>
      <div>&nbsp;</div>
      <h2 className="mt-2 mb-4">
        {props.children}
        <UpButton />
      </h2>
    </>
  )
}

function Page(props) {
  React.useEffect(() => Prism.highlightAll())

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Home
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#">
              Demo
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#quickstart">
              Quick Start
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#fields">
              Fields
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#errors">
              Errors
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#faq">
              FAQ
            </a>
          </li>
        </ul>
      </nav>

      <props.Demo />

      <div style={{ height: '85px' }}></div>

      <Headline id="quickstart">Quick Start</Headline>
      <props.QuickStart />

      <Headline id="fields">Supported Fields</Headline>
      <props.FieldNames />

      <Headline id="errors">Visualising errors</Headline>
      <p>
        You can either process validationStatus yourself and modify the form
        however you wish, or you can choose to use the built-in error
        visualisation feature.
      </p>
      <props.ErrorVisuals />

      <Headline id="faq">FAQ</Headline>
      <props.Faq />

      <UpButton />
      <p className="pb-5"></p>
    </>
  )
}

export function DemoFormReact() {
  return (
    <Page
      Demo={ReactDemo}
      QuickStart={ReactQuickStart}
      FieldNames={DocFields}
      ErrorVisuals={ReactErrorVisuals}
      Faq={Faq}
    />
  )
}

export function DemoFormPlain() {
  return (
    <Page
      Demo={PlainJsDemo}
      QuickStart={PlainJsQuickStart}
      FieldNames={DocFields}
      ErrorVisuals={PlainJsErrorVisuals}
      Faq={Faq}
    />
  )
}
