export default function bindIfStateCondition(
  context,
  {
    condition,
    bindAction = () => {},
    onViewBound = () => {},
    onViewUnbound = () => {},
  }
) {
  let unbind

  context.subscribe((update) => {
    if (condition(update)) {
      if (!unbind) {
        unbind = bindAction()
      }
      onViewBound()
    } else {
      if (unbind) {
        unbind()
        unbind = null
      }
      onViewUnbound()
    }
  })

  return () => {
    if (unbind) {
      unbind()
    }
  }
}
