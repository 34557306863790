import * as actions from './actions'
import * as selectors from './selectors'

export function googleMapsApi(state = { isLoaded: false }, action) {
  switch (action.type) {
    case actions.GOOGLE_MAPS_API_LOADED:
      return {
        ...state,
        isLoaded: true,
      }

    default:
      return state
  }
}

export { actions, selectors }
