import { hide, show } from '../domTools'
import { ADDRESS_FIELD_INVALID } from '../address'

const defaultErrorMessageMap = {
  streetName: 'Please enter a street address.',
  houseNumber: 'House number is missing',
  city: 'Please enter a town or city name.',
  state: 'Please enter a state, province, or region.',
  postalCode: {
    DEFAULT: 'Please enter a ZIP or postal code.',
    REASON_INVALID_FORMAT: 'ZIP must be in valid format',
  },
}

function retrieveErrorMessage(key, reason, errorMessageMap = {}) {
  let errorDef = errorMessageMap[key]

  if (!errorDef) {
    errorDef = defaultErrorMessageMap[key]
  }

  if (!errorDef) {
    return 'Error'
  }

  if (typeof errorDef === 'string') {
    return errorDef
  } else {
    return errorDef[reason] || errorDef['DEFAULT']
  }
}

function showError(errorElement, text) {
  show(errorElement)
  hide(errorElement.querySelector('[data-error-role="placeHolder"]'))

  const errorContent = errorElement.querySelector('[data-error-role="content"]')
  show(errorContent)

  if (errorContent) {
    errorContent.innerHTML = text
  } else {
    if (errorElement.children.length > 0) {
      throw new Error(
        'Could not update error visuals. Error visual either needs to be empty or contain an errorContent child.'
      )
    }
    errorElement.innerHTML = text
  }
}

function hideError(errorElement) {
  const errorContent = errorElement.querySelector('[data-error-role="content"]')
  if (errorContent) {
    const errorPlaceholder = errorElement.querySelector(
      '[data-error-role="placeHolder"]'
    )
    if (errorPlaceholder) {
      errorContent.innerHTML = ''
      hide(errorContent)
      show(errorPlaceholder)
    } else {
      hide(errorElement)
    }
  } else {
    errorElement.innerHTML = ''
    hide(errorElement)
  }
}

function hideAllErrors(root) {
  for (let el of root.querySelectorAll('[data-error-for-fields]')) {
    hideError(el)
  }
}

function updateErrorVisuals(
  formValidationStatus,
  { root = document, errorMessageMap } = {}
) {
  for (let errorElement of root.querySelectorAll('[data-error-for-fields]')) {
    hideError(errorElement)
    errorElement
      .getAttribute('data-error-for-fields')
      .split(' ')
      .reverse()
      .forEach((fieldName) => {
        const fieldStatus = formValidationStatus.fieldStatuses[fieldName]
        if (fieldStatus) {
          if (fieldStatus.shouldShowError === false) {
            hideError(errorElement)
            return false
          } else {
            if (fieldStatus.status === ADDRESS_FIELD_INVALID) {
              showError(
                errorElement,
                retrieveErrorMessage(
                  fieldName,
                  fieldStatus.reason,
                  errorMessageMap
                )
              )
            }
          }
        }
      })
  }
}

export { hideAllErrors, updateErrorVisuals, retrieveErrorMessage }
